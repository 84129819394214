/**
 * Const with params element
 * Hero type4 component
 * Display the hero type 4 for static page: h1 in grey box
 */
/* Import section */
import React from "react"
import Titledecorative from "../components/titledecorative"
import "../styles/components/herotype4.scss"

/* Declaration function */
const Herotype4 = ( { h1title} ) => {
  return (
    <div className="wrap-hero-type4">
      <Titledecorative titleType="h1" title= {h1title} />
    </div>
  )
}

/* Export function */
export default Herotype4

