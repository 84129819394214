/**
 * Const with params element
 * Truncate text component
 * Display a truncate text
 */

/* Declaration function */
const TruncateText = ({ str, length, ending }) => {
  var stripedHtml = str.replace(/<[^>]+>/g, '')
  var lenghtStrHtml = str.length

  if(lenghtStrHtml < length) {
     return stripedHtml.substring(0, length)
  } else {
    return (
      stripedHtml.substring(0, length - ending.length) + ending
    )
  }
}

/* Export function */
export default TruncateText


