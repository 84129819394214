/**
 * Thank You page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Herotype4 from "../components/herotype4"
import Geturlparameter from "../components/geturlparameter"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TruncateText from "../components/truncatetext"

const ThankyouPage = ({ data, search }) => {

  const { cpid } = search
   //Const data page
   const dataPage = data.wpPage
   const dataAcf = dataPage.acfPageThankyou
   var messThankyou = ""

   if(cpid === "get-started") {
    messThankyou = dataAcf.textGetStarted
   }else if(cpid === "live-demo") {
    messThankyou = dataAcf.textDemoLive
   }else if(cpid === "contact") {
    messThankyou = dataAcf.textContactUs
   }else {
    messThankyou= dataAcf.textGetStarted
   }

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Hero section */}
      <section className="section-hero-static top-section">
        <Herotype4 h1title={dataPage.title}/>
      </section>

      {/*Static page details section */}
      <section className="section-details-static">
        <div className="wrap-maincontent-static">
          <div className="maincontent-static post-content">
            <TruncateText str={messThankyou} length="5000" ending="" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "thank-you"}) {
      title
      acfPageThankyou {
        textContactUs
        textDemoLive
        textGetStarted
      }
      seo {
        title
        metaDesc
      }
    }
  }
`
/* Export page informations */
export default Geturlparameter(ThankyouPage)
